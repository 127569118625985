// import * as Sentry from "@sentry/node";
import { MediaContextProvider } from "components/Media/media";
import { ContentfulClientProvider } from "source/contentful/provider";
import App from "next/app";
import React, { ReactElement } from "react";
import dynamic from "next/dynamic";
import Head from "next/head";

// require("./base.less");
import "./base.scss";

import { HeadAnalytics } from "components/Analytics/Analytics";
import getBaseUrl from "@tiicker/util/lib/get-base-url";
import MetaTags from "components/MetaTags/MetaTags";
import MetaImage from "components/MetaTags/MetaImage";
import GlobalBanner from "components/GlobalBanner/GlobalBanner";
import { RecommendationProvider } from "components/RecommendationContext/RecommendationContext";
import { CartProvider } from "components/Cart/CartContext/CartContext";
import ImpersonationBanner from "components/ImpersonationBanner/ImpersonationBanner";
import Cookies from "js-cookie";
import TDError from "components/TdError/TDError";
import Notification from "components/Notification/Notification";
import AuthWrapper from "source/hoc/AuthWrapper";
import { CSSProperties } from "react";
import { PerkProvider } from "source/hoc/PerkProvider";
import { UserProvider } from "components/UserContext/UserContext";

import { withOptimizedLDProvider } from "source/launch-darkly/optimized-launchdarkly-integration";
import DevelopmentGatekeeper from "components/DevelopmentGatekeeper/DevelopmentGatekeeper";

// Font Awesome Next Stuff
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

// FingerprintJS Pro
import {
  FpjsProvider,
  FingerprintJSPro,
} from "@fingerprintjs/fingerprintjs-pro-react";

// Error reporting service
// Sentry.init({
//   dsn: process.env.SENTRY_DSN,
//   environment: process.env.SENTRY_ENV,
// });

const DynamicZaiusTag = dynamic(() => import("components/ZaiusTag/zaiusTag"), {
  ssr: false,
});

class MyApp extends App {
  componentDidMount() {
    // Add Cookieyes script
    const script = document.createElement("script");
    script.id = "cookieyes";
    script.type = "text/javascript";
    script.src =
      "https://cdn-cookieyes.com/client_data/2835f7ca5d653c77658f1379/script.js";
    document.head.appendChild(script);
  }

  render() {
    const { Component, pageProps } = this.props;
    // Workaround for https://github.com/zeit/next.js/issues/8592
    // @ts-ignore
    const { err } = this.props;
    const props = { ...pageProps, err };

    const baseUrl = getBaseUrl();
    const { openGraphTwitterData = [] } = pageProps;

    // NOTE! This variable is currently set to theme.less' @layout-header-height
    // plus 16px
    // message.config({
    //   top: 85,
    // });

    const isImpersonating = Cookies.get("impersonatedUser");

    const googleTagManagerId = "GTM-K9QFVXJ";
    const noscriptAnalyticsStyling: CSSProperties = {
      display: "none",
      visibility: "hidden",
    };

    const { native, mobile } =
      typeof window !== "undefined"
        ? this.props.router.query
        : { native: "true", mobile: "true" };

    return (
      <FpjsProvider
        loadOptions={{
          apiKey: "Fh6JkP6BlNDvrPeSQI17",
          endpoint: [
            "https://tiicker.com/W4bGGB2G10zGLAey/4Yc3QjKoNgX6jIGB",
            FingerprintJSPro.defaultEndpoint,
          ],
          scriptUrlPattern: [
            "https://tiicker.com/W4bGGB2G10zGLAey/SUXDWdOH4GZr6B1B?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>",
            FingerprintJSPro.defaultScriptUrlPattern,
          ],
        }}
      >
        <AuthWrapper>
          {/*@ts-ignore*/}
          <MediaContextProvider>
            {/*@ts-ignore*/}
            <ContentfulClientProvider>
              <MetaTags
                title="TiiCKER.com"
                description="The companies you own want to reward you! TiiCKER.COM makes it easy to discover stock perks you may qualify for based on your individual share ownership."
              />

              <MetaImage
                imageAlt="Where Individual Investors are rewarded for their loyalty"
                image={`${baseUrl}images/TiickerTM-WhiteBackground.png`}
              />
              <Head>
                <title>{pageProps.title}</title>

                <meta
                  name="description"
                  key="meta-description"
                  content={props.description}
                />
                <link
                  href="https://fonts.googleapis.com/css?family=DM+Serif+Display|Roboto:300,400,500|Bebas+Neue&display=swap"
                  rel="stylesheet"
                />
                <link
                  rel="stylesheet"
                  href="https://use.typekit.net/veu5vhv.css"
                />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=no"
                />
                <meta httpEquiv="content-language" content="en-us"></meta>

                {/* <meta property="og:type" key="type" content="website" />
                <meta
                  property="og:url"
                  key="url"
                  content={baseUrl.toString()}
                />
                
                <meta
                  property="og:site_name"
                  key="sitename"
                  content="TiiCKER.COM"
                /> */}
                <meta
                  name="twitter:card"
                  key="twittercard"
                  content="summary_large_image"
                />

                {openGraphTwitterData.map((ogt) => (
                  <meta {...ogt} />
                ))}

                <link
                  rel="icon"
                  type="image/x-icon"
                  href="/images/favicon.ico"
                />

                <script src="https://cdn.plaid.com/link/v2/stable/link-initialize.js"></script>
                <HeadAnalytics />
              </Head>

              <noscript>
                <iframe
                  src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
                  height="0"
                  width="0"
                  style={noscriptAnalyticsStyling}
                ></iframe>
              </noscript>

              <DynamicZaiusTag />
              <UserProvider>
                <RecommendationProvider>
                  <CartProvider>
                    {isImpersonating && <ImpersonationBanner />}
                    <Notification />
                    <PerkProvider>
                      <div id="modal-container"></div>
                      <div id="notification-container"></div>
                      <div id="message-container"></div>
                      <div
                        style={{ position: "relative", minHeight: "100vh" }}
                        id="menu-container"
                      >
                        {mobile !== "true" && <GlobalBanner />}
                        <DevelopmentGatekeeper>
                          <Component className="noop" {...props} />
                        </DevelopmentGatekeeper>
                      </div>
                    </PerkProvider>
                    <TDError />
                  </CartProvider>
                </RecommendationProvider>
              </UserProvider>
            </ContentfulClientProvider>
          </MediaContextProvider>
        </AuthWrapper>
      </FpjsProvider>
    );
  }
}

export default withOptimizedLDProvider(MyApp);
